<template>
  <div class="container-center">
    <div>
      <stepper
        :step="step"
        :stepper-length="stepLength"
        :step-label="stepLabel"
        :page-label="pageLabel"
      ></stepper>
    </div>
    <div class="p-3">
      <p class="text-32">{{ title }}</p>
      <p class="text-16">{{ subtitle }}</p>
    </div>
    <div class="row">
      <div class="col is-clickable center-flex" @click="play()">
        <div class="record-button-wrapper" :class="{ pulse: isPlaying }">
          <div class="record-button">
            <img src="@/assets/media/img/patterns/icon-play.svg" />
          </div>
        </div>
        <p>{{ $t("survey.recordingInstruction.play") }}</p>
      </div>
      <div class="col is-clickable" @click="redo()">
        <div><img src="@/assets/media/img/patterns/redo.svg" /></div>
        <div>{{ $t("survey.recordingInstruction.redo") }}</div>
      </div>
    </div>

    <div class="bottom center">
      <footer class="card-footer">
        <div class="card-footer-item">
          <b-button
            size="is-large"
            class="button is-primary voicemed-button"
            @click="onClick()"
          >
            {{ $t("survey.buttons.nextStep") }}
          </b-button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Stepper from "@/core/components/ui/Stepper";

export default {
  name: "Step4ResultRecord",
  components: { Stepper },
  methods: {
    onClick() {
      console.log("click");
      this.$emit("nextStep");
    },
    redo() {
      this.$emit("redo");
    },
    play() {
      this.isPlaying = !this.isPlaying;
      this.$emit("play", this.isPlaying);
    }
  },
  props: {
    subtitle: {},
    title: {},
    step: {},
    stepLength: {},
    stepLabel: {},
    pageLabel: { type: String },
    isRecording: { type: Boolean, default: false }
  },
  watch: {
    isRecording: function(currentValue) {
      //set current page and initCurrentPage
      this.isPlaying = currentValue;
    }
  },
  data: function() {
    return { isPlaying: this.isRecording };
  }
};
</script>

<style lang="scss" scoped>
.row {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.bottom {
  align-self: flex-end;
}
.center {
  width: 100%;
}
.bottom {
  @media screen and (max-width: 576px) and (min-height: 576px) {
    position: fixed;
    background: #fff;
    bottom: 0;
    left: 0;
    z-index: 99999;
    padding: 1rem 2.2rem;
  }
}
</style>
