<template>
  <div class="container-center">
    <stepper
      :step="step"
      :stepper-length="stepLength"
      :progress-bar="true"
      :step-label="stepLabel"
      :page-label="pageLabel"
    ></stepper>

    <div class="center">
      <div class="text-32 record-instruction-title">{{ title }}</div>
      <div class="subtitle text-16 gradiant-color record-instruction-subtitle">
        {{ subtitle }}
      </div>
    </div>
    <div class="center">
      <img src="@/assets/media/gif/recording.gif" />
    </div>
  </div>
</template>

<script>
import Stepper from "@/core/components/ui/Stepper";

export default {
  name: "Step3Recording",
  components: { Stepper },
  props: {
    subtitle: {},
    title: {},
    step: {},
    stepLength: {},
    stepLabel: {},
    recordTime: { type: Number, default: 5 },
    pageLabel: { type: String }
  },
  methods: {},
  data: function() {
    return {
      timeLeft: this.recordTime
    };
  },
  created: function() {
    const vm = this;
    var downloadTimer = setInterval(function() {
      if (vm.timeLeft <= 1) {
        clearInterval(downloadTimer);
        vm.$emit("nextStep");
      }
      vm.timeLeft -= 1;
    }, 1000);
    this.audioRec;
  }
};
</script>

<style lang="scss" scoped>
.center {
  margin-top: auto;
  width: 100%;
}
</style>
