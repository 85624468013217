<template>
  <div>
    <div class="checkbox-options">
      <div class="round">
        <label
          class="vmd-checkbox"
          v-for="(choice, index) of choices"
          :key="index"
        >
          <input
            type="checkbox"
            :value="choice.value"
            @click="emitValue($event, index, choice.name)"
            v-model="choice.value"
          />
          <div class="vmd-checkbox-cover">
            <span class="check"></span>
            <span class="control-label">{{
              translation[index].label || choice.label
            }}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckboxListV1",
  props: {
    choices: {
      type: Array,
      validator: items =>
        items.every(
          v =>
            v.label !== undefined &&
            v.name !== undefined &&
            v.value !== undefined
        )
    },
    translation: {
      type: Array,
      validator: items =>
        items.every(
          v =>
            v.label !== undefined &&
            v.name !== undefined &&
            v.value !== undefined
        )
    }
  },
  data: function() {
    return {
      choiceValues: null
    };
  },
  methods: {
    emitValue(event, index, name) {
      this.$emit("valueSelected", {
        index: index,
        name: name,
        value: event.target.checked
      });
    }
  }
};
</script>
<style scoped>
.vmd-checkbox-cover {
  margin: 0 0.75rem;
}

.vmd-checkbox-cover:first-of-type {
  margin-bottom: 0.75rem;
}

.vmd-checkbox-cover:last-of-type {
  margin-top: 0.75rem;
}
.vmd-checkbox-cover {
  display: inline-flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid transparent;
}
.vmd-checkbox input[type="checkbox"] {
  display: none;
}
.vmd-checkbox input[type="checkbox"] + .vmd-checkbox-cover .check {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid #d8f0bc;
  transition: background 0.15s ease-out;
  background: transparent;
  position: relative;
}

.vmd-checkbox input[type="checkbox"] + .vmd-checkbox-cover .check:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}

.vmd-checkbox input[type="checkbox"]:checked + .vmd-checkbox-cover .check {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.vmd-checkbox input[type="checkbox"]:checked + .vmd-checkbox-cover {
  border: 1px solid #ff4b7f;
  transition: border 0.25s ease-in;
}

.vmd-checkbox
  input[type="checkbox"]:checked
  + .vmd-checkbox-cover
  .check:after {
  opacity: 1;
}
.vmd-checkbox .control-label {
  padding-left: calc(0.75em - 1px);
}
.control-label {
  text-align: left;
}
.vmd-checkbox {
  display: flex;
}
</style>
