<template>
  <div class="container-center">
    <div>
      <stepper
        :step="step"
        :stepper-length="stepLength"
        :step-label="stepLabel"
        :page-label="pageLabel"
      ></stepper>
      <div>
        <img :src="img" style="" />
        <div class="text-16 recording-step-description" v-html="title"></div>
      </div>
    </div>
    <div class="bottom center">
      <p class="text-20">
        <img src="@/assets/media/img/patterns/sandglass.svg" /> {{ subtitle }}
      </p>
      <br />

      <footer class="card-footer">
        <div class="card-footer-item">
          <b-button
            size="is-large"
            class="button is-primary voicemed-button"
            @click="onClick()"
          >
            {{
              showVideo && this.isMobileScreen()
                ? $t("buttons.watchInAction")
                : $t("buttons.start")
            }}
          </b-button>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import Stepper from "@/core/components/ui/Stepper";

export default {
  name: "step-1-record-description",
  components: { Stepper },
  props: {
    img: {},
    step: {},
    stepLength: {},
    subtitle: {},
    title: {},
    stepLabel: {},
    pageLabel: { type: String },
    showVideo: { type: Boolean, default: false },
  },
  methods: {
    isMobileScreen() {
      return window.screen.width <= 576;
    },
    onClick() {
      this.$emit("nextStep");
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom {
  align-self: flex-end;
}

.center {
  width: 100%;
}
.text-16 {
  font-size: 16px;
}
img {
  max-width: 250px;
}
.recording-step-description {
  max-width: 500px;
}
</style>
