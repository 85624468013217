<template>
  <record-builder
    :step="2"
    :stepLength="3"
    :stepLabel="label"
    :pageLabel="pageLabel"
    :title="title"
    :subtitle="timerInstruction"
    :recordingTitle="recordingTitle"
    :recordingSubtitle="recordingSubtitle"
    :resultTitle="resultTitle"
    :resultSubtitle="resultSubtitle"
    :countdownTitle="countdownTitle"
    :img="recordingGif"
    :showVideo="showVideo"
    :hideAnimation="hideAnimation"
    :mp4Src="mp4Src"
    :webmSrc="webmSrc"
    @recordDone="recordBreath($event)"
    @next="moveTo('next')"
  ></record-builder>
</template>
<script>
import webmVid from "@/assets/media/video/breath_recording_flow.webm";
import mp4Vid from "@/assets/media/video/breath_recording_flow.mp4";
import RecordBuilder from "./utils/RecordBuilder";
import recordingGif from "@/assets/media/gif/breath-recording.gif";

export default {
  name: "BreathRecording",
  components: { RecordBuilder },
  props: {
    label: { type: String, default: "breath" },
    step: { type: Number, required: false },
    stepLength: { type: Number, required: false },
    title: {
      type: String,
      required: true,
      default: "The deep mouth breathe recording"
    },
    timerInstruction: { type: String, default: "6 seconds length" },
    recordingTitle: { type: String, default: "Breathe deeply" },
    recordingSubtitle: { type: String, default: "1 long time" },
    resultTitle: { type: String, default: "Nice one!" },
    resultSubtitle: { type: String, default: "Just one more step 💪" },
    pageLabel: { type: String },
    countdownTitle: { type: String },
    showVideo: { type: Boolean },
    hideAnimation: { type: Boolean, default: false },
    mp4Src: { type: String, default: mp4Vid },
    webmSrc: {
      type: String,
      default: webmVid
    }
  },
  data: () => ({
    isLoading: false,
    isRecording: false,
    recordingGif
  }),
  methods: {
    recordBreath(data) {
      this.$emit("output", data);
    },
    moveTo(context) {
      switch (context) {
        case "prev":
          this.$emit("prev");
          break;

        case "next":
          this.$emit("next");
          break;
      }
    }
  }
};
</script>
