<template>
  <record-builder
    :step="3"
    :stepLength="3"
    :stepLabel="label"
    :pageLabel="pageLabel"
    :title="title"
    :subtitle="timerInstruction"
    :recordingTitle="recordingTitle"
    :recordingSubtitle="recordingSubtitle"
    :resultTitle="resultTitle"
    :resultSubtitle="resultSubtitle"
    :countdownTitle="countdownTitle"
    :img="recordingGif"
    :showVideo="showVideo"
    :hideAnimation="hideAnimation"
    :mp4Src="mp4Src"
    :webmSrc="webmSrc"
    @recordDone="recordSpeech($event)"
    @next="moveTo('next')"
  ></record-builder>
</template>
<script>
import RecordBuilder from "./utils/RecordBuilder";
import webmVid from "@/assets/media/video/speech_recording_flow.webm";
import mp4Vid from "@/assets/media/video/speech_recording_flow.mp4";
import recordingGif from "@/assets/media/gif/speech-recording.gif";

export default {
  name: "SpeechRecording",
  components: { RecordBuilder },
  props: {
    label: { type: String, default: "Speech" },
    step: { type: Number, required: false },
    stepLength: { type: Number, required: false },
    title: {
      type: String,
      required: true,
      default: "The say “Ahhh” recording"
    },
    timerInstruction: { type: String, default: "6 seconds length" },
    recordingTitle: { type: String, default: "Speech" },
    recordingSubtitle: { type: String, default: "Say “Ahhh”" },
    resultTitle: { type: String, default: "Fantastic" },
    resultSubtitle: {
      type: String,
      default: "You successfully passed all the steps 🤗"
    },
    pageLabel: { type: String },
    countdownTitle: { type: String },
    showVideo: { type: Boolean, default: false },
    hideAnimation: { type: Boolean, default: false },
    mp4Src: { type: String, default: mp4Vid },
    webmSrc: {
      type: String,
      default: webmVid
    }
  },
  data: () => ({
    isLoading: false,
    isRecording: false,
    recordingGif
  }),
  methods: {
    recordSpeech(data) {
      this.$emit("output", data);
    },
    moveTo(context) {
      switch (context) {
        case "prev":
          this.$emit("prev");
          break;

        case "next":
          this.$emit("next");
          break;
      }
    }
  }
};
</script>
