<template>
  <div class="container-center">
    <stepper
      :step="step"
      :stepper-length="stepLength"
      :step-label="stepLabel"
      :page-label="pageLabel"
    ></stepper>

    <div class="center">
      <h2 class="title recording-title" v-if="title">{{ title }}</h2>
      <p class="gradiant-color recording-countdown">{{ timeLeft }}</p>
    </div>
  </div>
</template>

<script>
import Stepper from "@/core/components/ui/Stepper";

export default {
  name: "Step2TimerRecord",
  components: { Stepper },
  data: function() {
    return {
      timeLeft: 3
    };
  },
  props: {
    step: {},
    stepLength: {},
    stepLabel: {},
    pageLabel: { type: String },
    title: { type: String }
  },
  created: function() {
    const vm = this;
    var downloadTimer = setInterval(function() {
      if (vm.timeLeft <= 1) {
        clearInterval(downloadTimer);
        vm.$emit("nextStep");
      }
      vm.timeLeft -= 1;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.center {
  margin: auto;
  width: 100%;
}
.recording-title {
  font-size: 40px;
  @media screen and (max-width: 576px) {
    font-size: 30px;
  }
}
.recording-countdown {
  font-size: 128px;
  @media screen and (max-width: 576px) {
    font-size: calc(50px + 2vw);
  }
}
</style>
