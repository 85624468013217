<template>
  <div id="stepper">
    <div class="progressBar"></div>
    <div id="progressValue" class="progressBar progressValue"></div>
    <div id="description" v-if="!pageLabel">
      <b>Step {{ step }} on {{ stepperLength }}</b
      >- {{ stepLabel }}
    </div>
    <div id="description" v-else-if="pageLabel" v-html="pageLabel"></div>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  props: {
    step: { type: Number, required: false },
    stepperLength: { type: Number, required: false },
    stepLabel: { type: String, required: false },
    progressBar: { type: Boolean, required: false },
    pageLabel: { type: String }
  },
  mounted: function() {
    const vm = this;
    if (vm.progressBar === true) {
      document.getElementById("progressValue").classList.add("progressValue-5");
    }
  }
};
</script>

<style lang="scss" scoped>
#stepper {
  position: relative;
  top: 20px;
  min-width: 500px;
  @media screen and (max-width: 576px) {
    min-width: 80vw;
  }
}

.progressBar {
  position: absolute;
  height: 6px;
  left: 0%;
  right: 0%;
  top: 0px;

  /* Linear */
  background: linear-gradient(90deg, #ff0362 0%, #ff6207 100%);
  opacity: 0.21;
  border-radius: 100px;
}

.progressValue-5 {
  animation: load 6s normal linear;
  opacity: 1;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
#description {
  padding-top: 16px;
}

#description b {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;

  /* identical to box height */

  /* #3D3D3D */
  color: #3d3d3d;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}
</style>
