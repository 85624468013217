<template>
  <div class="container has-text-centered">
    <section class="card voicemed-card voicemed-card-centered-medium">
      <RecordDescription
        v-if="currentPage == 0"
        :stepLabel="stepLabel"
        :img="img"
        :step="step"
        :step-length="stepLength"
        :subtitle="subtitle"
        :title="title"
        :pageLabel="pageLabel"
        :showVideo="showVideo"
        @nextStep="nextStep()"
      />
      <VideoIllustration
        v-if="currentPage == 1"
        :mp4-src="mp4Src"
        :webm-src="webmSrc"
        @nextStep="nextStep()"
      />
      <timer
        v-if="currentPage == 2"
        :stepLabel="stepLabel"
        :step="step"
        :step-length="stepLength"
        :pageLabel="pageLabel"
        :title="countdownTitle"
        @nextStep="nextStep()"
      />
      <Recording
        v-if="currentPage == 3"
        :stepLabel="stepLabel"
        :title="recordingTitle"
        :subtitle="recordingSubtitle"
        :step="step"
        :step-length="stepLength"
        :pageLabel="pageLabel"
        @nextStep="nextStep()"
        :recordTime="6"
      />
      <ResultRecord
        v-if="currentPage == 4"
        :stepLabel="stepLabel"
        :pageLabel="pageLabel"
        :step="step"
        :step-length="stepLength"
        @redo="redo()"
        @play="play($event)"
        @nextStep="nextStep()"
        :title="resultTitle"
        :isRecording="isRecording"
        :subtitle="resultSubtitle"
      />
    </section>
    <audio id="myAudio" ref="audio" v-if="recordAudioUrl != null">
      <source :src="recordAudioUrl" type="audio/wav" />
    </audio>
  </div>
</template>
<script>
import Step1RecordDescription from "@/core/components/ui/audio-ui/utils/Step1RecordDescription";
import Step2Timer from "@/core/components/ui/audio-ui/utils/Step2Timer";
import Step4ResultRecord from "@/core/components/ui/audio-ui/utils/Step4ResultRecord";
import Step3Recording from "@/core/components/ui/audio-ui/utils/Step3Record";
import recorderLib from "@/core/plugins/audio";
import VideoIllustration from "./VideoIllustration.vue";

export default {
  name: "RecordBuilder",
  components: {
    Recording: Step3Recording,
    ResultRecord: Step4ResultRecord,
    Timer: Step2Timer,
    RecordDescription: Step1RecordDescription,
    VideoIllustration
  },
  props: {
    img: { type: String, required: false },
    step: {
      type: Number,
      required: false
    } /*pass every variables through the store*/,
    stepLength: { type: Number, required: false },
    stepLabel: {},
    timer: { type: Number, required: false },
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    resultTitle: { type: String, required: true },
    resultSubtitle: { type: String, required: true },
    recordingTitle: { type: String, required: true },
    recordingSubtitle: { type: String, required: true },
    recordMessage: { type: String },
    retryMessage: { type: String },
    successMessage: { type: String },
    showNavigation: { type: Boolean, default: true },
    pageLabel: { type: String },
    countdownTitle: { type: String },
    showVideo: { type: Boolean, default: false },
    hideAnimation: { type: Boolean, default: false },
    mp4Src: { type: String },
    webmSrc: { type: String }
  },
  data: function() {
    return {
      currentPage: 0,
      videoStepIndex: 1,
      illustrationSetpIndex: 0,
      isLoading: false,
      isRecording: false,
      recordData: null,
      recordAudioUrl: null,
      recorder: recorderLib
    };
  },
  computed: {},
  created() {
    //fast forward if show gif illustration is false - only hide gif iillustration for mobile screen
    if (
      this.currentPage == this.illustrationSetpIndex &&
      this.hideAnimation &&
      this.isMobileScreen()
    ) {
      this.currentPage++;
    }
  },
  methods: {
    isMobileScreen() {
      return window.screen.width <= 576;
    },
    nextStep() {
      this.currentPage++;
      //fast forward if show video illustration is false
      if (
        (this.currentPage == this.videoStepIndex && !this.showVideo) ||
        (this.currentPage == this.videoStepIndex && !this.isMobileScreen())
      ) {
        this.currentPage++;
      }
      if (this.currentPage == 3) {
        //start record
        this.startRecording();
      }
      if (this.currentPage == 4) {
        //end record
        this.endRecording();
      }
      if (this.currentPage > 4) {
        //stop audio if playing
        this.stopAudio();
        this.currentPage = 0;
        this.$emit("next");
      }
    },
    redo() {
      // Cleaning of recording
      this.currentPage = 2;
      this.recordAudioUrl = null;
      this.recordData = null;
      // stop audio if play;
      this.stopAudio();
      // restarting recording
      this.startRecording();
    },
    coughRecordCallback(data) {
      this.recordCough(data.blob);
    },
    startRecording() {
      this.recorder.start();
    },
    endRecording() {
      this.recordData = this.recorder.stop().blob;
      this.recordAudioUrl = window.URL.createObjectURL(this.recordData);
      this.$emit("recordDone", this.recordData);
    },
    play(shouldPlay) {
      this.isRecording = shouldPlay;
      const audioPlayer = this.$refs.audio;
      if (shouldPlay) {
        audioPlayer.play();
      } else {
        audioPlayer.pause();
        this.isRecording = false;
      }
      audioPlayer.addEventListener("ended", () => {
        this.isRecording = false;
      });
    },
    stopAudio() {
      try {
        const audioPlayer = this.$refs.audio;
        audioPlayer.pause();
        audioPlayer.currentTime = 0;
        this.isRecording = false;
      } catch (error) {
        console.log();
      }
    },
    moveTo(context) {
      switch (context) {
        case "prev":
          this.$emit("prev");
          break;

        case "next":
          this.$emit("next");
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  max-width: 250px;
}
</style>
