<template>
  <div>
    <section class="section px-2" v-if="isQuestionEnabled">
      <div class="container">
        <h5 class="title question">
          {{ translation.question || questionItem.question }}
        </h5>
        <div>
          <div v-if="questionItem.answerFieldType === 'RADIO_BUTTON'">
            <div
              v-for="(option, index) of questionItem.options"
              :key="option.value"
            >
              <b-radio v-model="questionAnswer" :native-value="option.value"
                >{{ translation.options[index].label || option.label }}
              </b-radio>
            </div>
          </div>
          <div v-if="questionItem.answerFieldType === 'RADIO_BUTTON_V1'">
            <radio-button-v-1
              :name="'question' + questionItem.id"
              :options="questionItem.options"
              :translation="translation.options"
              @valueSelected="questionItem.answer = $event"
              :radioValue="questionItem.answer"
            ></radio-button-v-1>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'INPUT_CHECKBOX'">
            <div
              class="field"
              v-for="(option, index) of questionItem.choices"
              :key="option.name"
            >
              <b-checkbox
                @input="updatedChoice($event, index)"
                v-model="option.value"
                >{{
                  translation.choices[index].label || option.label
                }}</b-checkbox
              >
            </div>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'INPUT_CHECKBOX_V1'">
            <checkbox-list-v-1
              :choices="questionItem.choices"
              :translation="translation.choices"
              @valueSelected="updatedChoice($event.value, $event.index)"
            ></checkbox-list-v-1>
          </div>
          <div
            v-else-if="questionItem.answerFieldType === 'INPUT_CITY_COUNTRY'"
          >
            <b-autocomplete
              :data="filteredCountryCityArray(questionItem.answer)"
              v-model="questionAnswer"
              placeholder="e.g. Stockholm, Sweden"
              icon="magnify"
              size="is-medium"
              clearable
              @select="(option) => (selected = option)"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </div>
          <div
            v-else-if="questionItem.answerFieldType === 'INPUT_CITY_COUNTRY_V1'"
          >
            <div class="voicemed-field-wrapper">
              <b-field
                :label="$t('fieldLabels.enterCity') || 'Enter City'"
                :label-position="'inside'"
              >
                <b-autocomplete
                  class="voicemed-input-field"
                  :data="filteredCountryCityArray(questionItem.answer)"
                  placeholder="-"
                  v-model="questionAnswer"
                  size="is-medium"
                  clearable
                  @select="(option) => (selected = option)"
                >
                  <template slot="empty">No results found</template>
                </b-autocomplete>
              </b-field>
            </div>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'INPUT_LANGUAGE'">
            <b-autocomplete
              :data="filteredNativeLanguage(questionItem.answer)"
              v-model="questionAnswer"
              placeholder="e.g. English"
              icon="magnify"
              size="is-medium"
              clearable
              @select="(option) => (selected = option)"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'INPUT_LANGUAGE_V1'">
            <div class="voicemed-field-wrapper">
              <b-field
                :label="$t('fieldLabels.enterLanguage') || 'Enter Language'"
                :label-position="'inside'"
              >
                <b-autocomplete
                  :data="filteredNativeLanguage(questionItem.answer)"
                  v-model="questionAnswer"
                  class="voicemed-input-field"
                  placeholder="-"
                  size="is-medium"
                  clearable
                  @select="(option) => (selected = option)"
                >
                  <template slot="empty">No results found</template>
                </b-autocomplete>
              </b-field>
            </div>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'SELECT_OPTION'">
            <b-field>
              <b-select
                :placeholder="
                  $t('fieldLabels.selectOption') || 'Select a option'
                "
                size="is-medium"
                expanded
                v-model="questionAnswer"
              >
                <option
                  :value="option.value"
                  v-for="(option, index) of questionItem.options"
                  :key="option.value"
                >
                  {{ translation.options[index].label || option.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'SELECT_OPTION_V1'">
            <div class="voicemed-field-wrapper">
              <b-field
                :label="$t('fieldLabels.selectOption') || 'Select a option'"
                :label-position="'inside'"
              >
                <b-select
                  class="voicemed-input-field"
                  size="is-medium"
                  placeholder="-"
                  expanded
                  v-model="questionAnswer"
                >
                  <option
                    :value="option.value"
                    v-for="(option, index) of questionItem.options"
                    :key="option.value"
                  >
                    {{ translation.options[index].label || option.label }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div
            v-else-if="
              questionItem.answerFieldType === 'RELATIVE_SELECT_OPTION_V1'
            "
          >
            <div
              class="relative-choice my-4"
              v-for="(
                relativeChoice, i
              ) of questionItem.relativeAnswerForChoices"
              :key="i"
            >
              <div class="voicemed-field-wrapper">
                <p class="has-text-left has-text-weight-bold">
                  {{
                    findRelativeChoiceTranslationByName(relativeChoice.name) ||
                    fetchTransactionFromExtras(relativeChoice.name) ||
                    relativeChoice.label
                  }}
                </p>
                <b-field
                  :label="
                    $t('fieldLabels.selectDuration') || 'Select a duration'
                  "
                  :label-position="'inside'"
                >
                  <b-select
                    class="voicemed-input-field"
                    size="is-medium"
                    placeholder="-"
                    expanded
                    v-model="relativeChoice.value"
                  >
                    <option
                      :value="option.value"
                      v-for="(option, index) of questionItem.options"
                      :key="option.value"
                    >
                      {{ translation.options[index].label || option.label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'INPUT_EMAIL'">
            <b-field>
              <b-input
                v-model="questionAnswer"
                type="email"
                maxlength="45"
                size="is-medium"
              ></b-input>
            </b-field>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'INPUT_EMAIL_V1'">
            <div class="voicemed-field-wrapper">
              <b-field
                :label="$t('fieldLabels.enterEmail') || 'Your Email'"
                :label-position="'inside'"
              >
                <b-input
                  class="voicemed-input-field"
                  v-model="questionAnswer"
                  type="email"
                  maxlength="45"
                  size="is-medium"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'INPUT_TEXT'">
            <b-field>
              <b-input
                v-model="questionAnswer"
                type="text"
                maxlength="45"
                size="is-medium"
              ></b-input>
            </b-field>
          </div>
          <div v-else-if="questionItem.answerFieldType === 'INPUT_TEXT_V1'">
            <div class="voicemed-field-wrapper">
              <b-field
                :label="$t('fieldLabels.enterValue') || 'Enter Value'"
                :label-position="'inside'"
              >
                <b-input
                  class="voicemed-input-field"
                  v-model="questionAnswer"
                  type="text"
                  maxlength="256"
                  size="is-medium"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div v-else>
            <b-field>
              <b-input
                v-model="questionAnswer"
                type="text"
                maxlength="45"
                size="is-medium"
              ></b-input>
            </b-field>
          </div>
          <!-- display input field based on the  question -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import csc from "country-state-city";
import { languages } from "@/core/plugins/popular-lang.js";
import CheckboxListV1 from "@/core/components/ui/form-fields-ui/CheckboxListV1";
import RadioButtonV1 from "@/core/components/ui/form-fields-ui/RadioButtonV1";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "QuestionItemV1",
  props: ["questionItem", "translation"],
  components: { CheckboxListV1, RadioButtonV1 },
  data: () => ({
    cscData: [].concat.apply(
      [],
      csc
        .getAllCountries()
        .map((c) =>
          csc.getStatesOfCountry(c.id).map((s) => `${s.name}, ${c.name}`)
        )
    ),
    relativeAnswerForChoicesTranslation: [],
  }),
  mounted() {
    // If this question is relative to a required question
    if (this.questionItem.answerFieldType == "RELATIVE_SELECT_OPTION_V1") {
      // fetch the required question
      let requiredQuestion = this.getSurveyQuestionById(
        this.questionItem.requiredIfAnswered
      );
      // check if the required question is a checkbox
      if (requiredQuestion.answerFieldType == "INPUT_CHECKBOX_V1") {
        // fetch the required question choices  (excluding the `none` answer)
        let requiredQuestionChoices = requiredQuestion.choices
          .filter((c) => c.value && c.name != "none")
          .map((choice) => ({
            name: choice.name,
            value: null,
            label: choice.label,
          }));

        // add cough choice to required question dat asked for cough if answered and symtoms with duration
        const coughQuestion = this.getSurveyQuestionByTag("Q_COUGH_PRESENCE");
        if (
          coughQuestion.answer == "YES" &&
          this.questionItem.tag == "Q_SYMPTOMS_WITH_DURATION"
        ) {
          requiredQuestionChoices.unshift({
            name: "cough",
            value: null,
            label: "Cough",
          });

          // also check if the value of the cough presence answer is not yes to remove if from remove it from value
        } else if (
          coughQuestion.answer != "YES" &&
          this.questionItem.tag == "Q_SYMPTOMS_WITH_DURATION"
        ) {
          this.questionItem.relativeAnswerForChoices =
            this.questionItem.relativeAnswerForChoices.filter(
              (c) => c.name != "cough"
            );
        }
        // remove already deleted answred choices if exists
        this.questionItem.relativeAnswerForChoices =
          this.questionItem.relativeAnswerForChoices.filter((c) =>
            requiredQuestionChoices.some((x) => x.name == c.name)
          );

        // fetch a slice for choices of current Question, for comparise
        let currentQuestionChoices =
          this.questionItem.relativeAnswerForChoices.slice();

        // get the filtered version,(containing only new choices)
        let filteredNewChoice = requiredQuestionChoices.filter((q) =>
          currentQuestionChoices.every((cq) => cq.name != q.name)
        );

        // merge filter version with current version of choice
        this.questionItem.relativeAnswerForChoices = [
          ...currentQuestionChoices,
          ...filteredNewChoice,
        ];
        this.questionItem.answer = "[RELATIVE_MULTICHOICE]";
        this.changeSurveyQuestionById({
          id: this.questionItem.id,
          questionItem: this.questionItem,
        });

        // get the translation of relative answer to choices
        // first retrive the translation by Id

        let requiredQuestionTranslation =
          this.getSurveyQuestionsTranslationById(
            this.questionItem.requiredIfAnswered
          );
        // fetch names of choices from relative choices
        let relativeAnswerForChoicesNames =
          this.questionItem.relativeAnswerForChoices.map((v) => v.name);
        this.relativeAnswerForChoicesTranslation =
          requiredQuestionTranslation.choices
            .filter((c) => relativeAnswerForChoicesNames.includes(c.name))
            .map((choice) => ({
              name: choice.name,
              value: null,
              label: choice.label,
            }));
      }
    }
  },
  computed: {
    ...mapGetters([
      "getSurveyQuestionById",
      "getSurveyQuestionByTag",
      "getSurveyQuestionsTranslationById",
    ]),
    isQuestionEnabled: function () {
      if (!this.questionItem.requiredIfAnswered) {
        return this.questionItem.enabled;
      }
      return (
        this.questionItem.enabled ||
        (this.getSurveyQuestionById(this.questionItem.requiredIfAnswered)
          .answer != null &&
          this.getSurveyQuestionById(this.questionItem.requiredIfAnswered)
            .answer != "NO")
      );
    },
    questionAnswer: {
      get() {
        return this.getSurveyQuestionById(this.questionItem.id).answer;
      },
      set(value) {
        this.questionItem.answer = value;
        return this.changeSurveyQuestionById({
          id: this.questionItem.id,
          questionItem: this.questionItem,
        });
      },
    },
  },
  methods: {
    filteredCountryCityArray(value) {
      return this.cscData.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(String(value).toLowerCase()) >= 0
        );
      });
    },

    filteredNativeLanguage(value) {
      return languages.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(String(value).toLowerCase()) >= 0
        );
      });
    },
    ...mapActions(["changeSurveyQuestionById"]),
    updatedChoice(value, index) {
      this.questionItem.choices.forEach((choice, i) => {
        //check if select choice is a `none of the above` option and then if it is set it to false
        if (
          (this.questionItem.choices[index].name == "none" ||
            this.questionItem.choices[index].name == "notSure") &&
          value &&
          choice.name != "none" &&
          choice.name != "notSure"
        ) {
          this.questionItem.choices[i].value = false;
        }
        //else check if its not a `NOTA`, if it isnt find NOTA and set it to false
        else if (
          this.questionItem.choices[index].name != "none" &&
          this.questionItem.choices[index].name != "notSure" &&
          value
        ) {
          //set none to false,
          //find index of none choice and set it to false
          let foundIndex = this.questionItem.choices.findIndex(
            (c) => c.name == "none"
          );
          if (foundIndex >= 0) {
            this.questionItem.choices[foundIndex].value = false;
          }
          let foundIndexNotSure = this.questionItem.choices.findIndex(
            (c) => c.name == "notSure"
          );
          if (foundIndexNotSure >= 0) {
            this.questionItem.choices[foundIndexNotSure].value = false;
          }
        }
      });
      this.questionItem.choices[index].value = value;
      this.questionItem.answer =
        this.questionItem.choices[index].name != "none" &&
        this.questionItem.choices[index].name != "notSure"
          ? // this.questionItem.tag != "Q_SYMPTOMS"
            "[MULTICHOICE]"
          : null;

      if (this.questionItem.tag == "Q_SYMPTOMS") {
        // check if it cough answer is yes, then update the answer as [Q_COUGH_PRESENCE_TRUE]
        const coughQuestion = this.getSurveyQuestionByTag("Q_COUGH_PRESENCE");
        this.questionItem.answer =
          coughQuestion.answer == "YES" ? "[Q_COUGH_PRESENCE_TRUE]" : null;
      }
      // Safe to say you dont need to change state for this
      // this.changeSurveyQuestionById({id : this.questionItem.id, questionItem : this.questionItem})
    },
    findRelativeChoiceTranslationByName(name) {
      let fetchedVal = this.relativeAnswerForChoicesTranslation.filter(
        (v) => v.name == name
      )[0];
      return fetchedVal ? fetchedVal.label : null;
    },
    fetchTransactionFromExtras(key) {
      return this.$t(`extras.${key}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.small-img {
  max-height: 400px;
}

.congrats-text {
  font-weight: bold;
  font-size: 30px;
  color: #ff4b7f !important;
}
.title {
  &.question {
    font-size: 20px;
    font-weight: normal;
  }
}
</style>
