<template>
  <div id="stepper">
    <div class="progressBar"></div>
    <div id="progressValue" class="progressBar progressValue"></div>
    <div id="description">
      <b>{{ description }}</b>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bar",
  props: {
    description: { type: String, required: false }
  }
};
</script>

<style lang="scss" scoped>
#stepper {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 32px;
}

.progressBar {
  position: absolute;
  height: 6px;
  left: 0%;
  right: 0%;
  top: 0px;

  /* Linear */
  background: linear-gradient(90deg, #ff0362 0%, #ff6207 100%);
  opacity: 0.21;
  border-radius: 100px;
}

#description {
  padding-top: 16px;
}

#description {
  b {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;

    /* identical to box height */

    /* #3D3D3D */
    color: #3d3d3d;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
  }
}
</style>
