<template>
    <div>
        <div class="radio-options">
                    <label v-for="(option, index) of options" :key="index">
                        <input type="radio" :name="name" :value="option.value" @click="setValue(option.value)" :checked="option.value == radioValue">
                        <div class="radio-box">
                            <span>{{ translation[index].label || option.label }}</span>
                        </div>
                    </label>
        </div>
    </div>
</template>
<script>
export default {
    name: "RadioButtonV1",
    props:{
        options : { 
            type : Array,
            validator: items => items.every(v => v.label !== undefined && v.value !== undefined) 
        },
        translation : { 
            type : Array,
            validator: items => items.every(v => v.label !== undefined && v.value !== undefined) 
        },
        name: String,
        radioValue : {type : String, default : null}
    },
    methods : {
        setValue(value){
            this.$emit('valueSelected', value)
        }
    }
}
</script>
<style scoped>
.radio-options label{
    margin: 0 .75rem;
    cursor: pointer;
}

.radio-options label:first-of-type{
    margin-bottom: .75rem;
}
 .radio-options label:last-of-type{
    margin-top: .75rem; 
 }

.radio-box{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 295px;
    padding: 0.3rem;
    min-height: 38px;
    background: #FFFFFF;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border : 1px solid transparent;
}
input[type="radio"]{
    display: none;
}

input[type="radio"]:checked +.radio-box {
    border : 1px solid #ff4b7f;
    transition: border .25s ease-in;
}
</style>