<template>
  <div>
    <div v-for="(questionsInPage, index) in questionsPerPage" :key="index">
      <!-- Q8 -->
      <template v-if="currentPage == index">
        <div class="card voicemed-card voicemed-card-centered-medium">
          <div class="question-section">
            <div
              class="card-top card-content survey-info-card has-text-centered"
            >
              <bar
                :description="
                  $t('pageLabels.questionItemPageLabel', {
                    pageNum: +index + 1
                  }) || `Question on Page ${+index + 1}`
                "
              ></bar>
              <div class="question-item">
                <question-item-v-1
                  v-for="question of questionsInPage"
                  :questionItem="question"
                  :translation="getQuestionTranslationById(question.id)"
                  :key="question.id"
                ></question-item-v-1>
              </div>
            </div>
            <div class="card-bottom bottom w-full">
              <footer class="card-footer">
                <div class="card-footer-item">
                  <b-button
                    size="is-large mr-2"
                    @click="movePage(index, 'prev')"
                    :disabled="index == 0"
                  >
                    <img src="/images/back.svg" />
                  </b-button>
                  <b-button
                    size="is-large"
                    class="voicemed-button next-button ml-2"
                    :disabled="false"
                    @click="movePage(index, 'next')"
                    >{{ $t("buttons.next") }}</b-button
                  >
                </div>
              </footer>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import QuestionItemV1 from "./QuestionItemV1";
import { mapActions, mapGetters } from "vuex";
import Bar from "@/core/components/ui/Bar.vue";
export default {
  name: "QuestionSectionV1",
  components: {
    QuestionItemV1,
    Bar
  },
  props: {
    questions: { type: Array, required: true },
    itemsPerPage: { type: Number, default: null },
    questionTranslations: { type: Array, default: null },
    setCurrentPage: { type: Number, default: null }
  },
  watch: {
    setCurrentPage: function(currentValue) {
      //set current page and initCurrentPage
      this.setCurrentPage = currentValue;
      this.initCurrentPageIfSet();
    }
  },
  created: function() {
    this.splitPageIfRequested();
    this.changeSurveyQuestions(this.questions);
    //ADD SURVEY QUESTION TRANSLATION
    this.changeSurveyQuestionsTranslation(this.questionTranslations);
    // confirm if pages were split and
    this.initCurrentPageIfSet();
    this.fastForwardPageIfHidden("specific");
  },

  data: function() {
    return {
      questionsPerPage: [],
      currentPage: 0,
      pages: [
        ...Array(Math.ceil(this.questions.length / this.itemsPerPage)).keys()
      ]
    };
  },
  computed: {
    ...mapGetters(["getSurveyQuestions", "getSurveyQuestionById"])
  },
  methods: {
    getQuestionTranslationById: function(id) {
      if (this.questionTranslations == null) return null;
      return this.questionTranslations.find(q => q.id == id);
    },
    emitPreviousEvent: function(payload) {
      this.$emit("previous", payload);
    },
    emitNextEvent: function(payload) {
      this.$emit("next", payload);
    },
    calculateSurveryCompletionPercentage: function() {
      const numberCompleted = this.getSurveyQuestions.filter(
        q => q.answer != null || q.requiredIfAnswered != null
      ).length;
      const total = this.getSurveyQuestions.length;
      const percentageCompleted = +((+numberCompleted / +total) * 100).toFixed(
        2
      );
      this.changeSurveyCompletionPercentage(percentageCompleted);
      return percentageCompleted;
    },
    movePage: function(pageIndex, action) {
      // console.log(pageIndex, action)
      // if(this.fastForwardPageIfHidden(action, (pageIndex))){
      //   return
      // }
      const closestVisiblePage = this.findClosestVisiblePage(action, pageIndex);
      var percentageCompleted = this.calculateSurveryCompletionPercentage();
      switch (action) {
        case "prev":
          this.currentPage =
            closestVisiblePage || (pageIndex > 0 ? pageIndex - 1 : 0);
          this.emitPreviousEvent({
            currentPage: this.currentPage,
            previousPage: pageIndex,
            percentageCompleted: percentageCompleted
          });
          break;
        case "next":
          if (this.currentPage == this.pages.length - 1) {
            this.$emit("end");
            return;
          }
          this.currentPage =
            closestVisiblePage ||
            (pageIndex < this.pages.length - 1 ? pageIndex + 1 : pageIndex);

          this.emitNextEvent({
            currentPage: this.currentPage,
            previousPage: pageIndex,
            percentageCompleted: percentageCompleted
          });
          break;
        case "specific":
          this.currentPage =
            pageIndex <= this.pages.length - 1
              ? pageIndex
              : pageIndex - 1 == -1
              ? 0
              : pageIndex;
          break;
        case "specific-next":
          this.currentPage =
            pageIndex <= this.pages.length - 1
              ? pageIndex
              : pageIndex - 1 == -1
              ? 0
              : pageIndex;
          this.emitNextEvent({
            currentPage: this.currentPage,
            previousPage: pageIndex,
            percentageCompleted: percentageCompleted
          });
          break;
        case "specific-prev":
          this.currentPage =
            pageIndex <= this.pages.length - 1
              ? pageIndex
              : pageIndex - 1 == -1
              ? 0
              : pageIndex;
          this.emitPreviousEvent({
            currentPage: this.currentPage,
            previousPage: pageIndex,
            percentageCompleted: percentageCompleted
          });
          break;
      }
      window.scrollTo({
        top: 5,
        behavior: "smooth"
      });
    },
    splitPageIfRequested: function() {
      if (this.itemsPerPage == null || this.itemsPerPage == undefined) {
        this.questionsPerPage = [].push(this.questions.slice());
        return;
      }
      let arrayOfarr = [];
      for (var x = 0; x < this.questions.length; x += this.itemsPerPage) {
        if (x + this.itemsPerPage <= this.questions.length) {
          arrayOfarr.push(this.questions.slice(x, x + this.itemsPerPage));
        } else {
          arrayOfarr.push(this.questions.slice(x, this.questions.length));
        }
      }
      this.questionsPerPage = arrayOfarr;
    },
    ...mapActions([
      "changeSurveyQuestions",
      "changeSurveyQuestionsTranslation",
      "changeSurveyCompletionPercentage"
    ]),
    initCurrentPageIfSet() {
      if (
        this.pages.length > 0 &&
        this.pages.length - 1 >= this.setCurrentPage &&
        this.setCurrentPage != null
      ) {
        this.currentPage = this.setCurrentPage;
      }
    },

    fastForwardPageIfHidden(action, index) {
      //TODO: [FIX] QuestionSection with a Single Optional QuestionItem per page should be skipped if hidden
      //check if the every question in the page is hidden,
      //if hidden, then move the page requiredAnswerField with the least value,
      //if not - move to the questions requiredAnswer question page
      //Check if question in a page are all hidden, if they all hidden move to the next page
      //if there is no next page call `end` event

      const pageIndex = index || this.currentPage;
      let pageForwarded = false;
      const currentPageQuestions = this.questionsPerPage[pageIndex];
      const optionalQuestions = currentPageQuestions.filter(
        q => q.requiredIfAnswered != null
      );
      if (
        optionalQuestions.length > 0 &&
        currentPageQuestions.every(
          q =>
            !q.enabled &&
            (this.getSurveyQuestionById(q.requiredIfAnswered).answer == null ||
              this.getSurveyQuestionById(q.requiredIfAnswered).answer == "NO")
        )
      ) {
        // if they are no questions is display
        // triggers fast foward logic
        const idOfFirstRequiredQuestions = Math.min(
          ...optionalQuestions.map(oq => oq.requiredIfAnswered)
        );
        const pageindexOfRequiredField = this.questionsPerPage.findIndex(qs =>
          qs.some(q => q.id == idOfFirstRequiredQuestions)
        );
        switch (action) {
          case "next":
            this.movePage(pageIndex + 1, action);
            pageForwarded = true;
            break;

          case "prev":
            this.movePage(pageIndex - 1, action);
            pageForwarded = true;
            break;

          case "specific":
            //get the lowest value for requiredIfAnswerd
            if (pageindexOfRequiredField > pageIndex) {
              this.movePage(pageindexOfRequiredField, "specific-next");
            } else if (pageindexOfRequiredField < pageIndex) {
              this.movePage(pageindexOfRequiredField, "specific-prev");
            } else {
              this.movePage(pageindexOfRequiredField, "specific");
            }
            pageForwarded = true;
            break;
        }
      }

      return pageForwarded;
    },
    findClosestVisiblePage(direction, fromIndex) {
      // if direction is next, find page with in which array inside has viewable pages else
      if (direction == "prev") {
        const pagesEnabled = this.questionsPerPage
          .map((x, i) => ({ index: i, detail: x }))
          .filter(
            q =>
              q.detail.some(
                q =>
                  q.enabled ||
                  ((q.answer != null || q.answer != "NO") &&
                    (q.requiredIfAnswered == null
                      ? true
                      : this.getSurveyQuestionById(q.requiredIfAnswered)
                          .answer != null) &&
                    (q.requiredIfAnswered == null
                      ? true
                      : this.getSurveyQuestionById(q.requiredIfAnswered)
                          .answer != "NO"))
              ) && q.index < fromIndex
          );
        const previousVisiblePage = Math.max(...pagesEnabled.map(x => x.index));
        return previousVisiblePage != Infinity ? previousVisiblePage : 0;
      } else if (direction == "next") {
        const pageindexOfRequiredField = this.questionsPerPage.findIndex(
          (qs, index) =>
            qs.some(
              q =>
                q.enabled ||
                ((q.answer != null || q.answer != "NO") &&
                  (q.requiredIfAnswered == null
                    ? true
                    : this.getSurveyQuestionById(q.requiredIfAnswered).answer !=
                      null) &&
                  (q.requiredIfAnswered == null
                    ? true
                    : this.getSurveyQuestionById(q.requiredIfAnswered).answer !=
                      "NO"))
            ) && (direction == "next" ? index > fromIndex : index < fromIndex)
        );
        return pageindexOfRequiredField;
      } else {
        return null;
      }
    },
    checkIfQuestionHasNullAnswer(questionId) {
      // eslint-disable-next-line no-unused-vars
      const selectedQuestion = this.getSurveyQuestionById(questionId);
      return (
        this.selectedQuestion.answer == null ||
        this.selectedQuestion.answer == "NO"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.small-img {
  max-height: 400px;
}
.congrats-text {
  font-weight: bold;
  font-size: 30px;
  color: #ff4b7f !important;
}
.card {
  min-height: 520px;
  margin-bottom: 2rem;
}
.question-item {
  margin-top: 4rem;
}
.next-button {
  &:hover {
    color: #fff !important;
  }
}
@media screen and (max-width: 576px) {
  .card-bottom {
    position: fixed;
    bottom: 0;
  }
  .next-button {
    min-width: 230px !important;
  }
}
@media all and (max-height: 625px) {
  .card-bottom {
    position: relative;
    margin-top: 5px;
    bottom: 0;
  }
}
</style>
