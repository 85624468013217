<template>
  <div class="container-center">
    <div class="video-container" @click="playAndUnmunte()">
      <video
        autoplay
        :muted="isPlayError"
        loop
        playsinline
        ref="video"
        id="videoPlayer"
      >
        <source :src="webmSrc" type="video/webm" />
        <source :src="mp4Src" type="video/mp4" />
      </video>
    </div>

    <div class="bottom center">
      <footer class="card-footer">
        <div class="card-footer-item">
          <a class="volume-span" @click="videoToggle()"
            ><img
              src="@/assets/media/img/icons/audio_volumn.svg"
              class="mr-2"
            />
            {{
              isPlayError
                ? $t("pageLabels.unmuteVideo")
                : $t("pageLabels.turnUpVolume")
            }}</a
          >
        </div>
        <div class="card-footer-item p-0">
          <b-button
            size="is-large"
            class="button is-primary voicemed-button"
            @click="onClick()"
          >
            {{ $t("buttons.start") }}
          </b-button>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import Bowser from "bowser";
export default {
  name: "VideoIllustration",
  props: {
    mp4Src: { type: String },
    webmSrc: { type: String },
    muted: { default: false, type: Boolean },
  },
  data() {
    return {
      autoPlayed: true,
    };
  },
  async mounted() {
    const promise = this.$refs.video.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          this.autoPlayed = true;
        })
        .catch(() => {
          this.autoPlayed = false;
        });
    }
  },
  computed: {
    isChromeBrowser() {
      const browser = Bowser.parse(window.navigator.userAgent);
      return browser.browser.name == "Chrome";
    },
    isPlayError() {
      return !this.autoPlayed;
    },
  },
  methods: {
    onClick() {
      this.$emit("nextStep");
    },

    playAndUnmunte() {
      const videoPlayer = this.$refs.video;
      videoPlayer.play();
    },

    videoToggle() {
      const videoPlayer = this.$refs.video;
      if (videoPlayer.muted) {
        videoPlayer.muted = false;
        videoPlayer.volume = 1;
      }
      if (videoPlayer.volume < 1) {
        videoPlayer.volume = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video-container {
  min-height: 400px;
  padding: 20px;

  video {
    max-width: 100%;
    height: 400px;
  }
  @media screen and (max-width: 576px) {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    height: 80vh;
    background: #bdbdbd;
    width: 100%;

    video {
      max-width: 100%;
      height: auto;
    }
  }
  @media all and (max-height: 640px) {
    top: -20vh;
  }
  @media all and (max-height: 525px) {
    top: -30vh;
  }
}
.card-footer {
  @media screen and (max-width: 576px) {
    background: #fff;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 9999;
    padding: 1rem 2rem;
  }
}
.bottom {
  align-self: flex-end;
}

.center {
  width: 100%;
}
.text-16 {
  font-size: 16px;
}
img {
  max-width: 250px;
}
.volume-span {
  color: #ff0362;
  display: inline-flex;
  cursor: pointer;
}
.card-footer-item {
  border: none;
}
</style>
